import { useEffect, useState } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import 'bootswatch/dist/darkly/bootstrap.min.css';
import './App.css';
// import RandomGroupComponent from './components/RandomGroup';

function App() {
  const availableServices = ["Netflix", "HBO Max", "Prime Video", "Hulu", "Disney+", "YouTube"];
  const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
  const maxInt = 20;

  const [ selectedService, setSelectedService ] = useState(-1);
  const [ selectedLetter, setSelectedLetter ] = useState(0);
  const [ selectedNum, setSelectedNum ] = useState(-1);
  const [ maxRandom, setMaxRandom ] = useState(maxInt);

  const maxItemsBeforeWeOnlyShowOneAtATime = 20;

  const GetRandomByMax = (max) => Math.floor(Math.random() * max);

  const Randomize = () => {
    let newServiceIdx = selectedService;
    let netLetterIdx = selectedLetter;
    let newNum = selectedNum;

    while (newServiceIdx === selectedService) { newServiceIdx = GetRandomByMax(availableServices.length); }
    while (netLetterIdx === selectedLetter) { netLetterIdx = GetRandomByMax(letters.length); }
    while (newNum === selectedNum) { newNum = GetRandomByMax(maxRandom); }

    setSelectedService(newServiceIdx);
    setSelectedLetter(netLetterIdx);
    setSelectedNum(newNum);
  }

  const NewMaxRand = (e) => {
    let incoming = e.target.value;
    if (!isNaN(incoming) && incoming > 0 && incoming != maxRandom)
      setMaxRandom(incoming);
  }

  useEffect(() => {
    Randomize();
  }, [ ])

  return (
    <Container>
      <Row>
        <Col sm={6} xs={12}>
          <h3>Service</h3>
          { availableServices.length <= maxItemsBeforeWeOnlyShowOneAtATime
          ?
            <table className="table">
            <tbody>
              { availableServices.map((item, idx) => {
                if (idx === selectedService)
                  return <tr className="table-info" key={`theTRNumber${idx}`}><td key={`theTDNumber${idx}`}>{item}</td></tr>
                else
                  return <tr className="table-secondary" key={`theTRNumber${idx}`}><td key={`theTDNumber${idx}`}>{item}</td></tr>
                })}
            </tbody>
          </table>
          :
            <h3 className="text-success">{availableServices[selectedService]}</h3>
          }
          
        </Col>
        <Col sm={3} xs={6}>
            <h2>Letter</h2>
            <h3 className="text-success">{letters[selectedLetter]}</h3>
        </Col>
        <Col sm={3} xs={6}>
            <Row>
                <h2>Number</h2>
                <h3 className="text-success">{selectedNum + 1}</h3>
            </Row>
        </Col>
      </Row>
      <Row>
          <Button className="btn btn-primary" onClick={Randomize}>Re-Roll</Button>
          <div className="form-group">
              <label className="form-label mt-4">Set max number:</label>
              <input className="form-control" type="number" min="1" value={maxRandom} onChange={NewMaxRand}></input>
          </div>
      </Row>
    </Container>
  );
}

export default App;
